
// @flow

import React from 'react'
import cn from 'classnames'
import Seedrandom from 'seedrandom'
import { withTranslation } from 'react-i18next'
import { Currency } from 'i18n/NumberFormatter'
import withTheme from 'hoc/withTheme'

import LoadingIndicator from 'components/LoadingIndicator'
import Button from 'components/Button'

import { Store } from 'Store.js'
import transDomain from './translations/index.translations'
import supportedThemes, { Theme } from './themes/__supportedThemes.js'

import getVehicleImageUrl from '../../util/getVehicleImageUrl'

import SEDAN_FALLBACK from './images/fallback/Sedan.png'
import SUV_FALLBACK from './images/fallback/SUV & Crossover.png'
import TRUCK_FALLBACK from './images/fallback/Truck.png'

import styles from './styles.module.scss'

function ImageFallback (props) {
    let url = null
    switch (props.segment) {
        case 'Truck':
            url = TRUCK_FALLBACK
            break
        case 'SUV & Crossover':
            url = SUV_FALLBACK
            break
        case 'Car/Sedan':
            url = SEDAN_FALLBACK
            break
        default:
            url = SEDAN_FALLBACK
    }
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img style={{ marginTop: 'auto' }} className={props.className} src={url} />
}

type Props = {
    theme: Theme,
    voucherValue: string,
    startingPrice: string,
    name: string,
    year: string,
    make: string,
    segment: string,
    colorProp?: string,
    title: string,
    selected: boolean,
    onClick: () => {},
    isVisible: boolean,
}

function CarVoucher (props: Props) {
    const { state } = React.useContext(Store)
    const ref = React.useRef(null)
    const currencyFormatter = Currency(state.currency, state.locale)
    const [imageError, setImageError] = React.useState(false)
    let [loaded, setLoaded] = React.useState(false)
    const [doingFetch, setIsDoingFetch] = React.useState(false)

    const color = React.useMemo(() => {
        if (props.colorProp) return props.colorProp
        const rng = new Seedrandom([props.make, props.name, props.year].join('-'))
        let color = props.theme.vehicleColors.sort(() => rng.quick() - 0.5)
        return Array.isArray(color) ? color.join(',') : color
    }, [props.make, props.name, props.year, props.colorProp, props.theme.vehicleColors])

    const handleImageLoad = React.useCallback(() => {
        setLoaded(true)
    }, [])

    const handleImageError = React.useCallback(() => {
        setImageError(true)
        setLoaded(true)
    }, [])

    const src = React.useMemo(() => {
        if (props.segment === 'Pre-Owned') {
            handleImageError(true)

            if (['GMC'].includes(props.make)) {
                return TRUCK_FALLBACK
            }
            if (['FORD'].includes(props.make)) {
                return SUV_FALLBACK
            }

            return SEDAN_FALLBACK
        }

        return getVehicleImageUrl(props.make, props.name, props.year, color, 'front_34', 'm', props.segment)
    }, [props.make, props.name, props.year, props.segment, color, handleImageError])

    const handleOnClick = React.useCallback(() => {
        setIsDoingFetch(true)
        props.onClick(props.voucherValue, props.startingPrice, props.make, props.name, props.year, color)
    }, [props, color])

    React.useEffect(() => {
        if (!doingFetch && props.selected && ref && ref.current) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            })
        }
    }, [props.selected, ref, doingFetch])

    return (
        <div ref={ref} className={cn(styles.carVoucher, {
            [styles.selected]: props.selected,
            [styles.isVisible]: props.isVisible
        })} style={{
            '--primary-font': props.theme.primaryFont,
            '--primary-color': props.theme.primaryColor,
            '--secondary-color': props.theme.secondaryColor,
            backgroundColor: props.theme.backgroundColor
        }}>
            {props.voucherValue > 0 && (
                <div className={styles.voucherTag} style={{
                    backgroundColor: props.theme.voucherTagBgColor
                }}>
                    Voucher Value <strong>&nbsp;{currencyFormatter.format(props.voucherValue)}</strong>
                </div>
            )}

            <div className={styles.body}>
                <div className={styles.title} style={{
                    color: props.theme.titleColor
                }}>
                    {props.title}
                </div>

                <div
                    className={cn(styles.wrapper, styles.carContainer, {
                        [styles.loaded]: loaded,
                        [styles.largeTitle]: props.title.length >= 35
                    })}>
                    {imageError ? (
                        <ImageFallback className={styles.isFallback} segment={props.segment} />
                    ) : (
                        <>
                            {!loaded && <LoadingIndicator small />}
                            <img
                                className={styles.img}
                                alt={`${props.year} ${props.name}`}
                                onLoad={handleImageLoad}
                                src={src}
                                onError={handleImageError}
                            />
                        </>
                    )}
                </div>
            </div>

            <div className={styles.separator} style={{ backgroundColor: props.theme.lineColor }} />

            <div className={styles.footer}>
                {props.startingPrice > 0 && (
                    <div className={styles.price}>
                        Starting at price:
                        <strong>{currencyFormatter.format(props.startingPrice)}*</strong>
                    </div>
                )}

                <Button className={cn(styles.btn, {
                    [styles.isSelected]: props.selected,
                    [styles.isLoading]: doingFetch
                })} onClick={handleOnClick} disabled={doingFetch} loading={doingFetch}>
                    {props.selected ? 'Selected' : 'Select'}
                </Button>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(CarVoucher))
