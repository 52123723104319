// @flow
import * as React from 'react'
import cn from 'classnames'

import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import { Store } from 'Store.js'

import supportedThemes from './themes/__supportedThemes.js'
import styles from './styles.module.scss'

import transDomain from './translations/index.translations'

import RegisterVoucherValue from 'components/RegisterVoucherValue'
import CashbackAndRates from 'components/CashbackAndRates'

function RegisterIncentives (props) {
    const { state } = React.useContext(Store)
    const manufacturer = state.campaign.incentives.manufacturer
    const hiddenRates = props.theme.hiddenRates  

    if (!manufacturer) return null

    return (
        <div
            className={cn(styles.registerIncentives, {
                [styles.hiddenRates]: hiddenRates,
                [styles.hiddenCashBack]: manufacturer.cashBack === 0,
                [styles.areCombinable]: manufacturer.areCombinable
            })}
            style={{ '--line-color': props.theme.lineColor }}
        >
            <div className={styles.container}>
                <RegisterVoucherValue className={styles.registerVoucherValue}/>
                <CashbackAndRates className={styles.cashbackAndRates}/>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(RegisterIncentives))
