/* eslint-disable react-hooks/exhaustive-deps */
// @flow

import React from 'react'
import { withTranslation } from 'react-i18next'
import Seedrandom from 'seedrandom'
import LoadingIndicator from 'components/LoadingIndicator'

import withTheme from 'hoc/withTheme'
import getVehicleImageUrl from '../../util/getVehicleImageUrl'
import supportedThemes from './themes/__supportedThemes.js'
import transDomain from './translations/index.translations'
import cn from 'classnames'
import { Currency } from 'i18n/NumberFormatter'
import { Store } from 'Store'
import styles from './styles.module.scss'

import SEDAN_FALLBACK from './images/fallback/Sedan.png'
import SUV_FALLBACK from './images/fallback/SUV & Crossover.png'
import TRUCK_FALLBACK from './images/fallback/Truck.png'

const LOADER = require('./images/loader.svg')

function ImageFallback ({ segment }) {
    let url = null
    switch (segment) {
        case 'Truck':
            url = TRUCK_FALLBACK
            break
        case 'SUV & Crossover':
            url = SUV_FALLBACK
            break
        case 'Car/Sedan':
            url = SEDAN_FALLBACK
            break
        default:
            url = SEDAN_FALLBACK
    }
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img className={styles.fallback} src={url} />
}

const SelectedCarVoucher = ({
    onClick = () => {},
    voucherValue,
    startingPrice,
    theme,
    name,
    year,
    make,
    segment,
    colorProp
}) => {
    const [loading, setLoading] = React.useState(false)
    const [imageError, setImageError] = React.useState(false)
    let [loaded, setLoaded] = React.useState(false)
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)

    const color = React.useMemo(() => {
        if (colorProp) return colorProp
        const rng = new Seedrandom([make, name, year].join('-'))
        let color = theme.vehicleColors.sort(() => rng.quick() - 0.5)
        return Array.isArray(color) ? color.join(',') : color
    }, [make, name, year, segment, voucherValue])

    const handleImageLoad = React.useCallback(() => {
        setLoaded(true)
    }, [])

    const handleImageError = React.useCallback(() => {
        setImageError(true)
        setLoaded(true)
    }, [])

    const src = React.useMemo(() => {
        if (segment === 'Pre-Owned') {
            handleImageError(true)

            if (['GMC'].includes(make)) {
                return TRUCK_FALLBACK
            }
            if (['FORD'].includes(make)) {
                return SUV_FALLBACK
            }
            return SEDAN_FALLBACK
        }

        return getVehicleImageUrl(make, name, year, color, 'front_34', 'm', segment)
    }, [make, name, year, segment, voucherValue, color])

    const handleOnClick = React.useCallback(() => {
        setLoading(true)
        onClick(color)
    }, [make, name, year, segment, voucherValue, src, color])

    const title = `${year || ''} ${name} ${segment === 'Pre-Owned' ? make : ''}`

    return (
        <div
            className={cn(styles.container, {
                [styles.imageFallback]: imageError,
            })}
            style={{
                '--primary-font': theme.primaryFont,
                borderColor: theme.lineColor
            }}
        >
            {voucherValue > 0 && (
                <span className={styles.voucherTag} style={{
                    backgroundColor: theme.voucherTagBgColor
                }}>
                    Voucher: <strong style={{ color: theme.voucherTagValueColor }}>&nbsp;{currencyFormatter.format(voucherValue)}</strong>
                </span>
            )}

            <div
                className={cn(styles.wrapper, styles.carContainer, {
                    [styles.loaded]: loaded,
                    [styles.largeTitle]: title.length >= 35
                })}
            >
                {imageError ? (
                    <ImageFallback segment={segment} />
                ) : (
                    <>
                        <LoadingIndicator small />
                        <img
                            src={src}
                            alt={`${year} ${name}`}
                            onLoad={handleImageLoad}
                            onError={handleImageError}
                        />
                    </>
                )}
            </div>

            <div className={styles.info}>
                <span
                    className={styles.title}
                    style={{
                        color: theme.primaryTextColor
                    }}
                >
                    {title}
                </span>
                {startingPrice && (
                    <span className={styles.value} style={{ color: theme.secondaryTextColor }}>
                        Starting at {currencyFormatter.format(startingPrice)}
                    </span>
                )}
            </div>

            <div
                onClick={handleOnClick}
                className={cn(styles.button, {
                    [styles.isLoading]: loading
                })}
                style={{
                    '--color-loader': `url(${LOADER})`,
                    '--primary-color': theme.primaryColor,
                }}
            >
                Change selection
            </div>
        </div>
    )
}

// const Loader = (props: { color: string }) => {
//     return (
//         <div className={styles.loader} style={{ borderTopColor: props.color }} />
//     )
// }

export default withTranslation(transDomain)(
    withTheme(supportedThemes)(SelectedCarVoucher)
)
