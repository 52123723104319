// @flow
import * as React from 'react'

import { Currency, Percent } from 'i18n/NumberFormatter'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'
import { formatDateTime } from 'i18n/DateFormat'

import transDomain from './translations/index.translations'
import { Store } from 'Store'
import confetti from './images/confetti.png'

import EventLogo from 'components/MainLogo/EventLogo'

type Props = {
    width: string, // eg. "541px"
    locale: string,
    campaign: { [string]: mixed },
}

const PromoVoucher = React.forwardRef(function (props: Props, ref: React.Ref) {
    const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)
    const { campaign, locale, currency } = state
    const { theme } = props
    const currencyFormatter = Currency(currency, locale)
    const manufacturer = campaign.incentives.manufacturer
    const hiddenRates = theme.hiddenRates
    const terms = wrapText(
        campaign.incentives.voucher.terms.replace('[dealership_name]', campaign.client.name),
        150
    )

    return (
        <svg
            width="100%"
            viewBox="0 0 541 216"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            ref={ref}
        >
            <defs>
                <linearGradient x1="50%" y1="20.5741808%" x2="50%" y2="100%" id="linearGradient-1">
                    <stop stopColor={theme.leftGradient.top} offset="0%"></stop>
                    <stop stopColor={theme.leftGradient.bottom} offset="100%"></stop>
                </linearGradient>
                <rect id="path-2" x="0" y="0" width="531" height="206" rx="4"></rect>
            </defs>
            <g>
                <rect id="Rectangle" fill={theme.secondary} x="0" y="0" width="541" height="216" rx="8"></rect>
                <g id="Rectangle" transform="translate(5.000000, 5.000000)">
                    <mask id="mask-3" fill="white">
                        <use xlinkHref="#path-2"></use>
                    </mask>
                    <use id="Mask" fill="url(#linearGradient-1)" xlinkHref="#path-2"></use>
                    <rect fill="#FFFFFF" mask="url(#mask-3)" x="310" y="-3" width="226" height="214"></rect>
                    <rect fill="#FFFFFF" mask="url(#mask-3)" x="-5" y="174" width="541" height="37"></rect>
                    <image x='0' y='0' preserveAspectRatio='xMinYMin meet' xlinkHref={confetti} height='100%' width='100%'/>
                </g>
            </g>
            <g transform="translate(0.000000, 159.000000)">
                <g fill={theme.secondary}>
                    <rect x="0" y="0" width="541" height="24"></rect>
                </g>
                <g transform="translate(5.000000, 0.000000)" fontWeight="normal" letterSpacing="0" fontFamily="proxima-nova, sans-serif">
                    <text fontFamily="proxima-nova, sans-serif" fontSize="8" letterSpacing="-0.08px" fontWeight="normal" fill={'#ffffff'}>
                        <tspan textAnchor="middle" x="263" y="14.5">{t('information', {
                            voucher_name: campaign.incentives.voucher.name,
                            expire_date: formatDateTime(campaign.expiryDate, 'long', null),
                            dealership_name: campaign.name
                        })}</tspan>
                    </text>
                    <text fontSize="5" fill={theme.textColor} textAnchor='middle'>
                        {terms.map((line, i) => (
                            <tspan key={i} x="50%" y={(terms.length > 2 ? 32 : 34) + i * (terms.length > 2 ? 7 : 8)}>{line}</tspan>
                        ))}
                    </text>
                </g>
            </g>

            {manufacturer && (
                <g transform="translate(315.000000, 82.000000)" fontWeight="normal" fontFamily="proxima-nova, sans-serif" letterSpacing="0">
                    {!(manufacturer.cashBack === 0) && (
                        <g transform={hiddenRates ? "translate(55.2500000, 32.000000)" : "translate(0.000000, 32.000000)"}>
                            <text fontSize="7" fill={theme.textColor}>
                                <tspan x="22.4260254" y="8">{t('cash_back')}</tspan>
                            </text>

                            {!hiddenRates && (
                                <text fontSize={campaign.incentives.manufacturer.areCombinable ? '18' : '11'} fill={theme.manufacturertTextColor}>
                                    <tspan textAnchor="middle" x="112" y="26">{campaign.incentives.manufacturer.areCombinable ? '+' : 'OR'}</tspan>
                                </text>
                            )}

                            <text fontSize="21" fill={theme.manufacturertTextColor} fontFamily="proxima-nova, sans-serif" letterSpacing="0" fontWeight="bold">
                                <tspan textAnchor="middle" x="56" y="30" fontSize="23">{`${currencyFormatter.format(campaign.incentives.manufacturer.cashBack)}`}</tspan>
                            </text>
                        </g>
                    )}

                    {!hiddenRates && (
                        <g transform={manufacturer.cashBack === 0 ? 'translate(62.000000, 32.000000)' : 'translate(111.000000, 32.000000)'}>
                            <text id="0.99%" fontSize="23" fill={theme.manufacturertTextColor} fontFamily="proxima-nova, sans-serif" letterSpacing="0" fontWeight="bold">
                                <tspan textAnchor="middle" x="56" y="30">{Percent(state.locale).format((Number.parseFloat(campaign.incentives.manufacturer.interestRate).toPrecision(3) / 100) || 0)}</tspan>
                            </text>
                            <text id="Rates-as-low-as" fontSize="7" fill={theme.textColor}>
                                <tspan x="30.2949219" y="8">{t('rates')}</tspan>
                            </text>
                        </g>
                    )}
                    <g fill={theme.textColor} fontSize="10">
                        <text>
                            <tspan textAnchor="middle" x="112" y="10">{t('combine.1')}</tspan>
                            <tspan textAnchor="middle" x="114" y="23">{t('combine.2')}</tspan>
                        </text>
                    </g>
                </g>
            )}

            <g transform="translate(20.000000, 28.000000)" fontWeight="normal" fontFamily="proxima-nova, sans-serif" letterSpacing="0">
                {state.lead.incentives.voucher.value > 0 ? (
                    <text fontSize="62" letterSpacing="-0.699999988" fill={theme.primary}>
                        <tspan textAnchor="middle" x="140" y="71" fontSize="78" letterSpacing="-2" fontWeight="900">
                            {currencyFormatter.format(state.lead.incentives.voucher.value)}
                        </tspan>
                    </text>
                ) : (
                    <text fontSize="13" fill={theme.textColor}>
                        <tspan textAnchor="middle" x="140" y="55" fontSize="13" letterSpacing="0" fontWeight="800">
                            {state.lead.incentives.voucher.name}
                        </tspan>
                    </text>
                )}
                <text fontSize="11.5" fill={theme.textColor}>
                    <tspan textAnchor="middle" x="140" y="5" fontFamily="proxima-nova, sans-serif" letterSpacing="0">
                        {t('voucher.prefix.1', {
                            firstName: state.lead.fields.firstName
                        })}
                    </tspan>
                </text>
                <text fontSize="11.5" fill={theme.textColor}>
                    <tspan textAnchor="middle" x="140" y="95" fontFamily="proxima-nova, sans-serif" letterSpacing="0">
                        {t('voucher.prefix.2', {
                            name: state.campaign.incentives.voucher.name,
                            value: state.lead.incentives.voucher.value
                        })}
                    </tspan>
                    <tspan textAnchor="middle" x="140" y="114" fontFamily="proxima-nova, sans-serif" letterSpacing="0">
                        {t('voucher.prefix.3', {
                            year: state.lead.fields.newVehicleModelYear,
                            model: state.lead.fields.newVehicleModel
                        })}
                    </tspan>
                </text>
            </g>
            <g transform={manufacturer ? 'translate(375.000000, -55.000000)' : 'translate(345.000000, -15.000000)'}>
                <EventLogo
                    y="40"
                    x="24"
                    width={manufacturer ? '64px' : '116px'}
                    height={manufacturer ? '116px' : '116px'}
                    primaryColor={theme.primaryColor}
                    secondaryColor={theme.secondaryColor}
                    ternaryColor={theme.ternaryColor}
                    ref={props.innerLogoRef}
                />
            </g>
        </svg>
    )
})

function wrapText (string: string, maxLength: number = 18) {
    return string.replace(new RegExp(`(?![^\\n]{1,${maxLength}}$)([^\\n]{1,${maxLength}})\\s`, 'g'), '$1\n').split('\n')
}

export default withTheme(supportedThemes)(PromoVoucher)
